import axios from "axios";
import moment from 'moment/moment';
import { getCompanyMetaData, getDefaultMetadata } from './api'

const location_map = new Map();
const reverseGeoSearch = async (lat, lon) => {
    try {
        const key = `${lat},${lon}`;
        if (location_map.has(key)) {
            return location_map.get(key);
        } else {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=18&addressdetails=0`);
            const data = response.data;
            location_map.set(key, data);
            return data;
        }
    } catch (error) {
        console.error("Error in reverseGeoSearch:", error);
        return null;
    }
}

const extractMetadataKeys = (metadata) => {
    return (
        metadata
            ?.filter((item) => item.metadata_value)
            .map((item) => item.metadata_key) || []
    );
};


export const exportToCSVForAllScans = async (scanResults, user, crop) => {
    const csvRows = [];
    const rows = [];
    const columnsArray = [];
    const [companyMetaDataResponse, defaultMetadataResponse] = await Promise.all([getCompanyMetaData(), getDefaultMetadata()]);
    const companyMetadata = companyMetaDataResponse?.data?.data?.metadata || [];
    const defaultMetadata = defaultMetadataResponse?.data?.data?.metadata || [];
    const companyMetadataKeys = extractMetadataKeys(companyMetadata);
    const defaultMetadataKeys = extractMetadataKeys(defaultMetadata);
    const metadataKeys = companyMetadataKeys.concat(defaultMetadataKeys);

    for (const scanResult of scanResults) {
        const columns = [
            'User Name',
            'Email/Phone Number',
            'ScanID',
            'Parent ScanID',
            'Total Scans',
            'Grain Type',
            'Machine',
            'Date',
            'Phone/Device Name',
        ];
        columns.push('Location')
        if (scanResult?.scan_result?.scan_result?.scan_result?.M5 && scanResult?.full_scan_Result !== 'undefined') {
            columns.push("Total Sample Tested Weight(gm)");
            columns.push("Avg Moisture(%)");
            columnsArray.push(columns);
            const response = await reverseGeoSearch(scanResult?.scan_result?.scan_result?.scan_metadata?.latitude, scanResult?.scan_result?.scan_result?.scan_metadata?.longitude)
            const addressData = response?.display_name || "Unknown";
            const row = [
                `${user?.first_name} ${user?.last_name}`,
                user?.email && user?.phone_number
                    ? `${user?.email} / ${user?.phone_number}`
                    : user?.email || user?.phone_number,
                scanResult?.scan_details[0].scan_id,
                "-",
                scanResult?.scan_details[scanResult?.scan_details.length - 1].sub_scan_id,
                `${scanResult?.crop_details?.crop} - ${scanResult?.crop_details?.variety}`,
                `${scanResult?.machine_details?.machine_name} (${scanResult?.machine_details?.machine_code})`,
                `"${moment(scanResult?.scan_details[0]?.scan_date).format(('DD MMM, YYYY hh:mm A'))}"`,
                `${scanResult?.scan_result?.scan_result?.scan_metadata?.device_info}`,
                `"${addressData}"`
            ]
            const { weightSum, moistureSum, objectCount } = scanResult?.full_scan_Result?.reduce(
                (acc, obj) => {
                    acc.weightSum += parseFloat(obj?.scan_metadata?.weight || 0);
                    acc.moistureSum += parseFloat(obj?.scan_metadata?.moisture || 0);
                    acc.objectCount += 1;
                    return acc;
                },
                { weightSum: 0, moistureSum: 0, objectCount: 0 }
            );
            const averageMoisture = (moistureSum / objectCount).toFixed(2);
            row.push(weightSum);
            row.push(averageMoisture);
            rows.push(row)
        } else {
            const dist_avg_indexes = scanResult?.scan_result?.scan_result?.dist_avg_indexes;
            const dist_keys_array = dist_avg_indexes?.distribution_avg_values_keys;
            dist_keys_array?.forEach((key) => {
                columns.push(key)
            });

            if (metadataKeys.length > 0) {
                metadataKeys?.forEach(value => {
                    columns.push(value);
                });
            }
            if (!scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                columns.push("Total Kernel Count");
                columns.push("Total Kernel Count %");
            }

            columns.push("Total Kernel Weight (gm)");
            columns.push("Total Kernel Weight (%)");

            if (scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                columns.push("Total Kernel Range");
            }
            scanResult?.scan_result?.scan_result?.kernel_details?.classes?.forEach((key, index) => {
                const kernelCount = String(scanResult?.scan_result?.scan_result?.kernel_details?.beautify?.translations?.kernel_counts[key]);
                const encoding = String(scanResult?.scan_result?.scan_result?.processed_image?.encodings[key]);
                const sanitizedKernelCount = kernelCount ? kernelCount.replace(/,/g, '-') : '';
                if (!scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                    columns.push(`${sanitizedKernelCount} (${encoding}) Count`);
                    columns.push(`${sanitizedKernelCount} (${encoding}) Count %`);
                }

                columns.push(scanResult?.scan_result?.scan_result?.kernel_details?.beautify?.translations?.kernel_counts[key] + " (" + scanResult?.scan_result?.scan_result?.processed_image?.encodings[key] + ")" + "Weight");
                columns.push(scanResult?.scan_result?.scan_result?.kernel_details?.beautify?.translations?.kernel_counts[key] + " (" + scanResult?.scan_result?.scan_result?.processed_image?.encodings[key] + ")" + "Weight %");

                if (scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                    columns.push(`${sanitizedKernelCount} (${encoding}) Range`);
                }
                if (scanResult?.scan_result?.scan_result?.kernel_details) {
                    columns.push(scanResult?.scan_result?.scan_result?.kernel_details?.beautify?.translations?.kernel_counts[key] + " (" + scanResult?.scan_result?.scan_result?.processed_image?.encodings[key] + ")" + "Area (mm2)");
                }
                if (scanResult?.scan_result?.scan_result?.kernel_details) {
                    columns.push(scanResult?.scan_result?.scan_result?.kernel_details?.beautify?.translations?.kernel_counts[key] + " (" + scanResult?.scan_result?.scan_result?.processed_image?.encodings[key] + ")" + "Length (mm)");
                }
            });
            columns.push("Moisture");
            columns.push("Moisture Weight");
            if (scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                columns.push("Moisture Range");
            }
            const final_array = [...dist_keys_array];
            const resultArray = [];
            final_array?.forEach((key) => {
                if (key in dist_avg_indexes?.beautify?.translations?.distribution_avg_values) {
                    const value = dist_avg_indexes?.distribution_avg_values[key] || "NA";
                    const dimension = dist_avg_indexes?.beautify?.dimensions?.distribution_avg_values[key] || "";
                    resultArray.push(`${value} ${dimension}`)
                }
            });
            const response = await reverseGeoSearch(scanResult?.scan_result?.scan_result?.scan_metadata?.latitude, scanResult?.scan_result?.scan_result?.scan_metadata?.longitude)
            const addressData = response?.display_name || "Unknown";
            const row = [
                `${user?.first_name} ${user?.last_name}`,
                user?.email && user?.phone_number
                    ? `${user?.email} / ${user?.phone_number}`
                    : user?.email || user?.phone_number,
                scanResult?.scan_details[0].scan_id,
                scanResult?.scan_result?.scan_result?.scan_metadata?.parent_scan_id || "-",
                scanResult?.scan_details[scanResult?.scan_details.length - 1].sub_scan_id,
                `${scanResult?.crop_details?.crop} - ${scanResult?.crop_details?.variety}`,
                `${scanResult?.machine_details?.machine_name} (${scanResult?.machine_details?.machine_code})`,
                `"${moment(scanResult?.scan_details[0]?.scan_date).format(('DD MMM, YYYY hh:mm A'))}"`,
                `${scanResult?.scan_result?.scan_result?.scan_metadata?.device_info}`,
                `"${addressData}"`,
                ...resultArray,

            ]

            if (metadataKeys) {
                metadataKeys && metadataKeys.map(key => {
                    row.push(scanResult?.scan_result?.scan_result?.scan_metadata?.user_metadata?.[key] !== undefined && scanResult?.scan_result?.scan_result?.scan_metadata?.user_metadata?.[key] !== '' ? scanResult?.scan_result?.scan_result?.scan_metadata?.user_metadata?.[key] : '-');
                });
            }
            if (!scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                row.push(scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_count);
                row.push("100%");
            }
            if (parseFloat(scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_weight) > 0) {
                row.push(parseFloat(scanResult?.scan_result?.scan_result?.scan_metadata?.weight) > 0 ? parseFloat(scanResult?.scan_result?.scan_result?.scan_metadata?.weight).toFixed(3) : parseFloat(scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_weight).toFixed(3))
                row.push("100%");
            } else {
                row.push("NA")
                row.push("NA");
            }
            if (scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                row.push("NA");
            }
            scanResult?.scan_result?.scan_result?.kernel_details?.classes?.forEach((key, index) => {
                if (!scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                    row.push(Number(scanResult?.scan_result?.scan_result?.kernel_details?.kernel_counts[key]));
                    row.push(` ${Number(scanResult?.scan_result?.scan_result?.kernel_details?.kernel_counts[key] / scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_count * 100).toFixed(2)}%`);
                }
                if (parseFloat(scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_weight) > 0) {
                    let weightKey = scanResult?.scan_result?.scan_result?.kernel_details?.kernel_weights ? scanResult?.scan_result?.scan_result?.kernel_details?.kernel_weights : scanResult?.scan_result?.scan_result?.kernel_details?.kernel_weight;
                    if (parseFloat(scanResult?.scan_result?.scan_result?.scan_metadata?.weight) === 0) {
                        row.push(Number(weightKey[key]).toFixed(3))
                        row.push(Number(weightKey[key] / scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_weight * 100).toFixed(2) + "%")
                    } else {
                        const weightedPercentageOfTotal = Number(weightKey[key] / scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_weight * 100)
                        const finalWeightFromSampleWeight = Number(weightedPercentageOfTotal * parseFloat(scanResult?.scan_result?.scan_result?.scan_metadata?.weight ? scanResult?.scan_result?.scan_result?.scan_metadata?.weight : scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_weight) / 100)
                        row.push(Number(finalWeightFromSampleWeight).toFixed(3))
                        row.push(Number(weightKey[key] / scanResult?.scan_result?.scan_result?.kernel_details?.total_kernel_weight * 100).toFixed(2) + "%")
                    }
                } else {
                    row.push("NA")
                    row.push("NA")
                }
                if (scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                    const value = scanResult?.scan_result?.scan_result?.kernel_details?.class_ranges[key];
                    row.push(value > 0 ? value : 'NA');
                }

                if (scanResult?.scan_result?.scan_result?.kernel_details?.consider_area) {
                    const areaValue = scanResult.scan_result.scan_result.kernel_details.areas[key];
                    if (areaValue !== 'NaN' && !isNaN(Number(areaValue))) {
                        row.push(Number(areaValue).toFixed(2));
                    } else {
                        row.push('-');
                    }
                } else {
                    row.push('-');
                }

                if (scanResult?.scan_result?.scan_result?.kernel_details?.consider_length) {
                    const lengthValue = scanResult.scan_result.scan_result.kernel_details.kernel_lengths[key];
                    if (lengthValue !== 'NaN' && !isNaN(Number(lengthValue))) {
                        row.push(Number(lengthValue).toFixed(2));
                    } else {
                        row.push('-');
                    }
                } else {
                    row.push('-');
                }

            });
            if (scanResult?.scan_result?.scan_result?.scan_metadata) {
                row.push(scanResult?.scan_result?.scan_result?.scan_metadata?.moisture ? scanResult?.scan_result?.scan_result?.scan_metadata?.moisture + "%" : 'NA');
            }
            if (scanResult?.scan_result?.scan_result?.scan_metadata) {
                row.push(scanResult?.scan_result?.scan_result?.scan_metadata?.moisture_weight ? scanResult?.scan_result?.scan_result?.scan_metadata?.moisture_weight + "gm" : 'NA');
            }
            if (scanResult?.scan_result?.scan_result?.kernel_details?.grading) {
                row.push(scanResult?.scan_result?.scan_result?.kernel_details?.moisture_range ? scanResult?.scan_result?.scan_result?.kernel_details?.moisture_range : 'NA');
            }
            rows.push(row)
            columnsArray.push(columns);
        }
    }

    const maxColumns = columnsArray.reduce((max, current) => Math.max(max, current.length), 0);
    const headers = columnsArray.find(col => col.length === maxColumns);

    const table = {
        title: "",
        subtitle: "",
        headers: headers,
        rows: rows
    };
    const headerRow = table.headers.map((column) => column);
    csvRows.push(headerRow.join(','));
    table.rows.forEach((row) => {
        const csvRow = row.map((cell) => {
            const cellValue = cell.toString().replace(/"/g, '""');
            return `"${cellValue}"`;
        });
        csvRows.push(csvRow.join(','));
    });
    const csvData = csvRows.join('\n');
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    link.target = '_blank';
    link.download = `${crop}.csv`;
    link.click();
    location_map.clear()
};

export const onGetFormatedDateRange = (selected_option) => {
    // Compute the date range based on the selected option
    const today = new Date();
    let startDate = null;
    let endDate = today;

    if (selected_option === 'all') {
        startDate = new Date(2022, 0, 1); // January 1, 2022
    } else if (selected_option === 'today') {
        startDate = new Date(today);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
    } else if (selected_option === 'this_week') {
        const today = new Date();
        const currentDay = today.getDay();
        const lastWeekStart = new Date(today);
        lastWeekStart.setDate(today.getDate() - currentDay);
        startDate = lastWeekStart;
        endDate = today;
    } else if (selected_option === 'this_month') {
        const today = new Date();
        const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate = currentMonthStart;
        endDate = today
    } else if (selected_option === 'this_year') {
        const today = new Date();
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = today;
    }
    // Format dates to the specified form
    const formattedStartDate = startDate.toISOString();
    const formattedEndDate = endDate.toISOString();
    return { start_date: formattedStartDate, end_date: formattedEndDate };
}

export const getMonthAbbreviation = (month_number) => {
    switch (month_number) {
        case '01':
            return 'Jan';
        case '02':
            return 'Feb';
        case '03':
            return 'Mar';
        case '04':
            return 'Apr';
        case '05':
            return 'May';
        case '06':
            return 'Jun';
        case '07':
            return 'Jul';
        case '08':
            return 'Aug';
        case '09':
            return 'Sep';
        case '10':
            return 'Oct';
        case '11':
            return 'Nov';
        case '12':
            return 'Dec';
        default:
            return 'Invalid Month';
    }
}